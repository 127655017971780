import React from "react"
import { getBackgroundImage } from "../../../plugins/common"

const Header = ({ header }) => {
  const { backgroundImage, title } = header
  const bg = getBackgroundImage(backgroundImage)

  return (
    <div
      className={"d-grid"}
      style={{
        height: "300px",
        backgroundRepeat: "repeat-x",
        placeContent: "center",
        background: "round",
        backgroundImage: `url(${bg})`,
      }}
    >
      <h1 className={`text-center ming`}>{title}</h1>
    </div>
  )
}

export default Header
