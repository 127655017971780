import React, { useState } from "react"

import Layout from "../../components/Layout"
import { getAllLocales, getCurrentLanguagePage } from "../../plugins/common"
import Header from "./components/Header"
import QuoteForm from "./components/QuoteForm"
import BookCallSteps from "./components/BookCallSteps"
import { staticText } from "../../enumerator/staticText"

const QuoteTemplate = ({ pageContext, location }) => {
  const { node, general, form, translation } = pageContext

  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const aleaToken = pageContext.general.aleaToken
  const enableFooterForm = node.footerForm?.enableFooter
  const footerForm = {
    ...node.footerForm,
    ...form.freeQuote,
  }

  const [selectedType, setSelectedType] = useState(
    node.formCard.find(obj => obj.typePipedriveId === null)
  )
  const [submitted, setSubmitted] = useState(false)
  let selectedLang = translation.find(obj => obj.locale === lang).form

  const handleQuoteType = value => {
    const type = node.formCard.find(obj => obj.typePipedriveId === value)
    type
      ? setSelectedType(type)
      : setSelectedType(node.formCard.find(obj => obj.typePipedriveId === null))
  }

  const handleSubmitted = value => {
    setSubmitted(value)
  }

  return (
    <Layout
      props={pageContext.general}
      meta={node.meta}
      localizations={getAllLocales(node.localizations, node.locale)}
      location={location}
      lang={lang}
      hideNewsletter={true}
      token={aleaToken}
      footerForm={enableFooterForm ? footerForm : null}
    >
      <Header header={node.header} />
      <div className="container">
        <div className="row">
          <QuoteForm
            formIntro={node.form}
            getAQuote={node.getAQuote}
            handleQuoteType={handleQuoteType}
            lang={lang}
            token={aleaToken}
            location={location}
            translation={selectedLang}
            hanndleSubmitted={handleSubmitted}
            type={staticText.GETAQUOTE}
          />
          {!submitted && selectedType && (
            <BookCallSteps
              selectedType={selectedType}
              translation={selectedLang}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default QuoteTemplate
