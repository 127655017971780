import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../../../components/Button"
import { openPopupWidget } from "react-calendly"
import { coloringText, CustomMarkdown } from "../../../plugins/sanitizehtml"

const BookCallSteps = ({ selectedType, translation }) => {
  const {
    title,
    subtitle,
    content,
    processTitle,
    processStep,
    bookCallEnable,
  } = selectedType
  const { bookCallLabel } = translation

  const openCalendly = () =>
    openPopupWidget({ url: "https://calendly.com/alea-care/intro-call" })

  const stepBgImages = [
    <StaticImage
      src={"../../../images/get-a-quote/resource-step-one.png"}
      alt=""
      layout="constrained"
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
      }}
    />,
    <StaticImage
      src={"../../../images/get-a-quote/resource-step-two.png"}
      alt=""
      layout="constrained"
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
      }}
    />,
    <StaticImage
      src={"../../../images/get-a-quote/resource-step-three.png"}
      alt=""
      layout="constrained"
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
      }}
    />,
  ]

  return (
    <div className={`col-lg-6 col-xs-12 mb-5`}>
      <div className={`row h-100 m-auto justify-content-center`}>
        <div
          className={`row soft-corners border border-ming p-5`}
        >
          <div className={`mb-3 col-lg-12`}>
            <h2 className={`ming lora-Bold`}>
              <CustomMarkdown html={title} color="copper" />
            </h2>
          </div>
          <div className={`mb-3`}>
            <div className={`mb-3`}>
              <h3 className={`mb-2 ming lora-Bold`}>
                <CustomMarkdown html={subtitle} color="copper" />
              </h3>
              <div className={`mb-4 ming lora`}>
                <CustomMarkdown html={content} color="copper" />
              </div>
              <h3 className={`mb-2 ming lora-Bold`}>
                <CustomMarkdown html={processTitle} color="copper" />
              </h3>
              <div className={`col-12 mb-4`}>
                {processStep?.map((step, index) => (
                  <div className={`flex col-lg-12 col-12`} key={step.id}>
                    <div
                      className={`col-lg-2 col-2 pb-2 px-1`}
                      style={{ position: "relative" }}
                    >
                      {stepBgImages[index]}
                      <h2
                        className={`ming lora text-center`}
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%,-50%)",
                        }}
                      >
                        {index + 1}
                      </h2>
                    </div>
                    <div className={`col-lg-10 col-10 pb-2`}>
                      <div className={`ming font-weight-bold`}>{step.title}</div>
                      <div className={`ming lora`}>
                        <CustomMarkdown
                          html={step.content}
                          color="lora-Bold ming"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {bookCallEnable && (
            <div className="mt-3 mx-0 px-0 row align-items-end">
              <div className="flex justify-content-center">
                <Button
                  type="primaryCornersMing"
                  text={bookCallLabel}
                  event={openCalendly}
                  stopPropagation={true}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BookCallSteps
